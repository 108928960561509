import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"

const SnowPounceDetail = ({ data }) => (
  <Layout pageHeaderText="Snow Pounce" pageHeaderImage="forest.png">
    <SEO title="SnowPounce" />
    <h2
      style={{
        textAlign: "center",
        fontSize: "3em",
        marginTop: "1rem",
        marginBottom: "2rem",
      }}
    >
      Snow Pounce
    </h2>
    <p style={{ textAlign: "center", maxWidth: "960px", margin: "0 auto" }}>
      This is a small mobile game I developed from scratch in two weeks over my holiday break in 2019. App store links coming soon!
    </p>
    <a
      style={{display: "block", textAlign:"center", marginTop:"2rem", marginBottom:"2rem", fontWeight: "700", fontSize: "1.5rem" }}
      href="https://apps.apple.com/us/app/snow-pounce/id1493702259"
      target="_blank"
      rel="noopener noreferrer"
      >
        Snow Pounce for iOS
      </a>
      <a
        style={{display: "block", textAlign:"center", marginTop:"2rem", marginBottom:"3rem", fontWeight: "700", fontSize: "1.5rem" }}
        href="https://play.google.com/store/apps/details?id=com.DevDuckLLC.SnowPounce"
        target="_blank"
        rel="noopener noreferrer"
      >
        Snow Pounce for Android
      </a>
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "auto",
        gridColumnGap: "4rem",
        gridRowGap: "2rem",
        marginBottom: "1rem",
        marginTop: "1rem",
      }}
    >
      <Img fluid={data.snowpounceone.childImageSharp.fluid}/>
      <Img fluid={data.snowpouncetwo.childImageSharp.fluid}/>
      <Img fluid={data.snowpouncethree.childImageSharp.fluid}/>
      <Img fluid={data.snowpouncefour.childImageSharp.fluid}/>
    </div>
    <Link style={{display:"block", textAlign:"right"}}to="/apps/snowpounce/privacy-policy">Privacy Policy</Link>
    <Link style={{display:"block", textAlign:"right"}}to="/apps/snowpounce/terms-and-conditions">Terms and Conditions</Link>
  </Layout>
)

export default SnowPounceDetail

export const query = graphql`
  query {
    snowpounceone: file(relativePath: { eq: "snowpounce-0.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    snowpouncetwo: file(relativePath: { eq: "snowpounce-1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    snowpouncethree: file(relativePath: { eq: "snowpounce-2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    snowpouncefour: file(relativePath: { eq: "snowpounce-3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
